// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

import('src/plugins') // loads async

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

$(function() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').prop('content')
        }
    });
});

$(document).on('click', '.destroy-action-btn', function() {
    let conf = confirm('Are you sure?');
    if(!conf) {
        return ;
    }
    const path = $(this).data('path');
    const model = $(this).data('model');
    const id = $(this).data('id');
    const redirect = typeof $(this).data('redirect') != "undefined" ? $(this).data('redirect') : '';

    $.ajax({
        url: path,
        type: 'DELETE',
        dataType: 'json',
        success: function(data) {
            if(redirect !== '') {
                location.href = redirect;
            } else {
                $(`#${model}_${id}`).remove();
            }
        },
        error: function(data) {
            console.log('error');
        }
    });
});

import 'css/site'

Rails.start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
